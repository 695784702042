<template>
  <div class="min-h-screen bg-blue-dark flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="px-16">
        <img src="@/assets/img/specifi_logo_white.svg">
      </div>
      <h2 class="mt-8 text-center text-3xl font-bold text-gray">
        Sign in to your account
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="py-8 px-4 sm:px-10">
        <div v-if="error" class="rounded-md bg-red-50 p-4 mb-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">
                {{ error }}
              </h3>
            </div>
          </div>
        </div>

        <form class="space-y-6" @submit.prevent="submit">
          <div>
            <label for="email" class="block text-sm font-medium text-gray">
              Email Address
            </label>
            <div class="mt-1 relative">
              <input id="email" v-model="loginData.email" name="email" type="email" autocomplete="email" required placeholder="john@specifi.io" class="form-input" :class="invalidLoginDetails && 'form-input-error'">
              <div v-if="invalidLoginDetails" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray">
              Password
            </label>
            <div class="mt-1 relative">
              <input id="password" v-model="loginData.password" name="password" type="password" autocomplete="current-password" required placeholder="********" class="form-input" :class="invalidLoginDetails && 'form-input-error'">
              <div v-if="invalidLoginDetails" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input id="remember_me" v-model="loginData.remember_me" name="remember_me" type="checkbox" :value="true" class="h-4 w-4 text-blue-light focus:ring-indigo-500 border-gray-300 rounded">
              <label for="remember_me" class="ml-2 block text-sm text-green-light">
                Remember me
              </label>
            </div>
          </div>

          <div>
            <button type="submit" class="group relative w-full flex justify-center py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-green-light to-green-light hover:to-green-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-dark">
              <span class="absolute left-0 inset-y-0 flex items-center pl-3"><svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green"><path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" /></svg></span> Login
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      loginData: {
        email: "",
        password: "",
        remember_me: false,
        isDesign: true
      },
      error: null,
      invalidLoginDetails: false
    };
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    async submit () {
      await this.signIn(this.loginData).then((res) => {
        console.log(res);
      }).catch((error) => {
        if (error.response.status === 401) {
          this.invalidLoginDetails = true;
          this.error = error.response.data.error;
        } else if (error.response.status === 402) {
          this.error = error.response.data.error;
        } else if (error.response.status === 403) {
          this.error = error.response.data.error;
        } else {
          this.error = 'Something went wrong, please try again.';
        }
      });

      this.$router.replace({ name: 'projects' });
    }
  }
};
</script>